import React from 'react';
import cx from 'classnames';
import './index.css';
import {FormattedMessage} from "react-intl";

export default ({large}) => {
    const classes = cx({
        'buy-now-button': true,
        'buy-now-button--large': large
    });
    return (
        <a
            className={classes}
            target="_blank"
            href="https://www.ipharma.ee/ee/vitamiinid/c-vitamiin"
        >
            <FormattedMessage id="button.buy.now.text" />
        </a>
    );
}
