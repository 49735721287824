import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-who-re" id="vitamin-who-re">
        <HeaderText/>
        <div className="vitamin-who-re-items">
            <Item
            large
                logo="/static/images/vitamin-who-re-diet.png"
                logo375="/static/images/vitamin-who-re-diet375.png"
            >
                <div className="vitamin-who-re-item-left-text">                
                    <FormattedMessage id="vitamin.who.re.item.diet" />
                </div>
            </Item>
            <Item
                logo="/static/images/vitamin-who-re-gold.png"
                logo375="/static/images/vitamin-who-re-gold375.png"
            >
                <div 
                    className="vitamin-who-re-item-center-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.who.re.item.gold'})}}
                />
            </Item>
            <Item
                logo="/static/images/vitamin-who-re-work.png"
                logo375="/static/images/vitamin-who-re-work375.png"
            >
                <div className="vitamin-who-re-item-right-text">
                    <FormattedMessage id="vitamin.who.re.item.work" />
                </div>
            </Item>
        </div>
    </div>
));