import React from 'react';
import './Header.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="footer-header">
        <div className="footer-header__upper">
            <FormattedMessage id="footer.shops.header" />
        </div>
        <div className="footer-header__lower">
            <FormattedMessage id="footer.shops.text" />
        </div>
    </div>
);
