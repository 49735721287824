import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-deficiency">
        <div 
            className="vitamin-deficiency__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.header'})}}
        />
        <div className="vitamin-deficiency__items">
            <Item
                url="/static/svg/vitamin-deficiency-stress.svg"
                text={<FormattedMessage id="vitamin.deficiency.item.stress" />}
            />
            <Item
                url="/static/svg/vitamin-deficiency-ill.svg"
                text={<FormattedMessage id="vitamin.deficiency.item.ill" />}
            />
            <Item
                url="/static/svg/vitamin-deficiency-bruise.svg"
                text={<FormattedMessage id="vitamin.deficiency.item.bruise" />}
            />
        </div>
    </div>
));
