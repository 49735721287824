import React from 'react';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="share">
        <span className="share__text">
            <FormattedMessage id="share.text" />
        </span>
        <span className="share__icons">
                <a className="addthis_button_facebook">
                        <img
                            src="/static/svg/facebook.svg"
                        />
                </a>
                <a className="addthis_button_messenger">
                        <img
                            src="/static/svg/messenger.svg"
                        />
                </a>
                <a className="addthis_button_twitter">
                        <img
                            src="/static/svg/twitter.svg"
                        />
                </a>
                <a className="addthis_button_email">
                        <img
                            src="/static/svg/email.svg"
                        />
                </a>
                <a className="addthis_button_compact">
                        <img
                            src="/static/svg/plus.svg"
                        />
                </a>
        </span>
    </div>
);
