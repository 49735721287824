import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="vitamin-who" id="vitamin-who">
        <HeaderText/>
        <div className="vitamin-who-items">
            <Item
                logo="/static/svg/vitamin-who-mother.svg"
            >
                <div className="vitamin-who-item-left-text">                
                    <FormattedMessage id="vitamin.who.item.mother" />
                </div>
            </Item>
            <Item
                logo="/static/svg/vitamin-who-elderly.svg"
            >
                <div className="vitamin-who-item-center-text">
                    <FormattedMessage id="vitamin.who.item.elderly" />
                </div>
            </Item>
            <Item
                logo="/static/svg/vitamin-who-young.svg"
            >
                <div className="vitamin-who-item-right-text">
                    <FormattedMessage id="vitamin.who.item.young" />
                </div>
            </Item>
        </div>
    </div>
);