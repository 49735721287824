import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({logo, logo375, header, children, large}) => {
    const classes = cx({
            'vitamin-who-re-item': true,
            'vitamin-whi-re-item--large': large
    });
    return (
        <div className={classes}>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet={logo375}
                />
                <img 
                    src={logo}
                />
            </picture>
            <div>
                {header}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}