import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="vitamin-who-header-text">
        <FormattedMessage id="vitamin.who.header" />
    </div>
);

