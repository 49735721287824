import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, children, large, largeTxt}) => {
        const classes = cx({
                'increased-need-item': true,
                'increased-need-item--large': large
        })
        const classesTxt = cx({
                'increased-need-item__text': true,
                'increased-need-item__text--large': largeTxt
        });
        return (
            <div className={classes}>
                    <img
                        className="increased-need-item__img"
                        src={url}
                    />
                    <div className="increased-need-item__header">
                            {header}
                    </div>
                    <div className={classesTxt}>
                            {children}
                    </div>
            </div>
        );
}
