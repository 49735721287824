import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'top-banner__bottom-gradient': true,
        'top-banner--ru': locale === 'ru'
    });
    const classesBtl = cx({
        'top-banner__image-bottle': true,
        'top-banner__image-bottle--ru': locale === 'ru'
    });
    const classesTxt = cx({
        'top-banner__text': true,
        'top-banner__text--ru': locale === 'ru'
    });
    return (
        <div className="top-banner">
            <img
                src="/static/images/top-banner.png"
                alt=""
                className="top-banner__image"
            />
            <img
                src="/static/images/top-banner--mobile.png"
                alt=""
                className="top-banner__image--mobile"
            />
            <img
                src="/static/images/375.png"
                alt=""
                className="top-banner__image--mobile375"
            />
            <div
                className={classes}
            >
                <div className={classesTxt}>
                    <FormattedMessage id="top.banner.text" />
                </div>
                <img
                src="/static/images/top-banner-bottle.png"
                alt=""
                className={classesBtl}
                />
                <img
                src="/static/images/top-banner-24h.png"
                alt=""
                className="top-banner__image-24h"
                />
            </div>
            
        </div>
    );
}
