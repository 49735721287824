import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="vitamin-need">
        <HeaderText/>
        <div className="vitamin-need-items">
            <div className="vitamin-need-items--top">
                <Item
                    small
                    logo="/static/images/vitamin-need-collagen.png"
                    text ={<FormattedMessage id="vitamin.need.item.1" />}
                >     
                </Item>
                <Item
                    small
                    logo="/static/images/vitamin-need-skin.png"
                    text={<FormattedMessage id="vitamin.need.item.2" />}
                >
                </Item>
            </div>
            <div className="vitamin-need-items--bottom">
                <Item
                    logo="/static/images/vitamin-need-stress.png"
                    text={<FormattedMessage id="vitamin.need.item.3" />}
                >   
                </Item>
                <Item
                    logo="/static/images/vitamin-need-iron.png"
                    text={<FormattedMessage id="vitamin.need.item.4" />}
                >    
                </Item>
                <Item
                    logo="/static/images/vitamin-need-brain.png"
                    text={<FormattedMessage id="vitamin.need.item.5" />}
                >    
                </Item>
            </div>
        </div>
    </div>
);