import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-sources__items-text': true,
        'vitamin-sources__items-text--ru': locale === 'ru'
    });
    
    return (
    
        <div className="vitamin-sources">
            <div 
                className="vitamin-sources__header"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.header'})}}
            />
            <div className="vitamin-sources__items">
                <div className="vitamin-sources__items--fruit">
                    <div className="vitamin-sources__items-header">
                        <FormattedMessage id="vitamin.sources.fruit.header" />
                    </div>
                    <div 
                        className="vitamin-sources__items-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.fruit.text'})}}
                    />
                </div>
                <div className="vitamin-sources__items--berries">
                    <div className="vitamin-sources__items-header">
                        <FormattedMessage id="vitamin.sources.berries.header" />
                    </div>
                    <div 
                        className="vitamin-sources__items-text vitamin-sources__items-text--center"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.berries.text'})}}
                    />
                </div>
                <div className="vitamin-sources__items--veg">
                    <div className="vitamin-sources__items-header">
                        <FormattedMessage id="vitamin.sources.veg.header" />
                    </div>
                    <div 
                        className={classes}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.veg.text'})}}
                    />
                </div>
            </div>
        </div>
    );
})
