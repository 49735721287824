import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/ee/vitamiinid/c-vitamiin">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="https://www.benu.ee/tooted/vitamiinid-ja-mineraalained/monovitamiinid/vitamiin-c?vars/brand/pro-expert">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/tooted/vitamiinid/vitamiinid-ja-mineraalained/monovitamiinid/c-vitamiin?brand=PRO+EXPERT">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/search?q=vitamin+c+pro+expert/">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/tooted/vitamiinid-ja-toidulisandid/vitamiinid-ja-mineraalained/monovitamiinid/c-vitamiin?brand=PRO+EXPERT">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
