import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="forget-tiredness">
        <HeaderText/>
        <div className="forget-tiredness-items">
            <Item
                logo="/static/svg/forget-tiredness-mood.svg"
                header={<FormattedMessage id="forget.tiredness.mood.header" />}
            >
                <div 
                    className="forget-tiredness-item-left-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'forget.tiredness.mood.text'})}}
                />
            </Item>
            <Item
                logo="/static/svg/forget-tiredness-health.svg"
                header={<FormattedMessage id="forget.tiredness.health.header" />}
            >
                <div className="forget-tiredness-item-center-text">
                    <FormattedMessage id="forget.tiredness.health.text" />
                </div>
            </Item>
            <Item
                logo="/static/svg/forget-tiredness-gut.svg"
                header={<FormattedMessage id="forget.tiredness.gut.header" />}
            >
                <div 
                    className="forget-tiredness-item-right-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'forget.tiredness.gut.text'})}}
                />
            </Item>
        </div>
    </div>
));