import React from 'react';
import BuyNowButton from '../../common/buy-now-button'
import './index.css';

export default () => (
    <div className="top-buy-now">
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/top-buy-now-bottle375.png"
            />
            <img 
                className="top-buy-now__bottle"
                src="/static/images/top-buy-now-bottle.png"
            />
        </picture>
        <span className="top-buy-now__button">
            <BuyNowButton large/>
        </span>
    </div>
);
