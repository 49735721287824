import React from 'react';
import './Contact.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

const Item = ({url, children, InnerHTML}) => (
    <div className="footer-contact__item">
        <img
            src={url}
            alt=""
        />
        <span dangerouslySetInnerHTML={InnerHTML}>
            {children}
        </span>
    </div>
);

export default injectIntl(({intl, locale}) => {
    const phoneText = intl.formatMessage({id: 'footer.contacts.phone'});
    const emailText = intl.formatMessage({id: 'footer.contacts.email'});
    const classes = cx({
        'footer-contact': true,
        'footer-contact--ru': locale === 'ru'
    });
    return(
        <div id="footer-contact" className={classes}>
            <Item url="/static/svg/contact-house.svg">
                <FormattedMessage id="footer.contacts.company" />
            </Item>
            <Item url="/static/svg/contact-location.svg">
                <a href="https://www.google.com/maps/place/P%C3%A4rnu+mnt+141,+11314+Tallinn/">
                    <FormattedMessage id="footer.contacts.address" />
                </a>
            </Item>
            <Item url="/static/svg/contact-phone.svg">
                <a href={`tel:${phoneText}`}>
                    {phoneText}
                </a>
            </Item>
            <Item url="/static/svg/contact-email.svg">
                <a href={`mailto:${emailText}`}>
                    {emailText}
                </a>
            </Item>
            <Item 
                url="/static/svg/contact-clock.svg"
                InnerHTML={{__html: intl.formatMessage({id: 'footer.contacts.hours'})}}
            />
        </div>
    )
});
