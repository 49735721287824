import React from 'react';
import './HeaderText.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div 
        className="vitamin-need-header-text"
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.need.header'})}}
    />
));

