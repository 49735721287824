import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="increased-need" id="increased-need">
        <div 
            className="increased-need__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'increased.need.header'})}}
        />
        <div className="increased-need__items">
            <Item
                url="/static/images/increased-need-smoke.png"
            >
                <div>
                    <FormattedMessage id="increased.need.item.smoke" />
                </div>
            </Item>
            <Item
                url="/static/images/increased-need-pregnant.png"
            >
                <div>
                    <FormattedMessage id="increased.need.item.pregnant" />
                </div>
            </Item>
        </div>
        <div className="increased-need__items--middle">
            <Item
                large
                largeTxt
                url="/static/images/increased-need-basket.png"
                >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'increased.need.item.basket'})}}
                />
            </Item>
        </div>
        <div className="increased-need__items increased-need__items--bottom">
            <Item
                url="/static/images/increased-need-iron.png"
                >
                <div
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'increased.need.item.iron'})}}
                />
            </Item>
            <Item
                url="/static/images/increased-need-ill.png"
                >
                <div>
                    <FormattedMessage id="increased.need.item.ill" />
                </div>
            </Item>
        </div>
    </div>
));
