import React from 'react';
import './HeaderBottom.css';

export default () => {
    return (
        <div className="header-bottom">
            <img
                src="/static/svg/banner-title.svg"
                alt="pro expert vitamin C"
                className="header-bottom__logo"
            />
            <img
                src="/static/svg/logo-background.svg"
                alt=""
                className="header-bottom__logo-background"
            />
        </div>
    );
};