import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-iron-header__text2': true,
        'vitamin-iron-header__text2--ru': locale === 'ru'
    });
    
    return (
        <div className="vitamin-iron" id="vitamin-iron">
            <div className="vitamin-iron__wrap">
                <div 
                    className="vitamin-iron-header__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.iron.header'})}}
                />
                <div className={classes}>
                    <FormattedMessage id="vitamin.iron.header2" />
                </div>
                <div className="vitamin-iron-content">
                    <div className="vitamin-iron-content__item">
                        <img
                            src="/static/svg/vitamin-iron-point.svg"
                        />
                        <div 
                            className="vitamin-iron-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.iron.item.1'})}}
                        />
                    </div>
                    <div className="vitamin-iron-content__item">
                        <img
                            src="/static/svg/vitamin-iron-point.svg"
                        />
                        <div className="vitamin-iron-content__text">                        
                            <FormattedMessage id="vitamin.iron.item.2" />
                        </div>
                    </div>
                    <div className="vitamin-iron-content__item">
                        <img
                            src="/static/svg/vitamin-iron-point.svg"
                        />
                        <div 
                            className="vitamin-iron-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.iron.item.3'})}}
                        />
                    </div>
                </div>
            </div>
            <div className="vitamin-iron__image">
                <div className="vitamin-iron__image--wrap">
                    <img alt="" src="/static/images/vitamin-iron-img.png" />
                </div>
            </div>
        </div>
    );
})
