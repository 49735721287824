import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-zinc">
        <div className="vitamin-zinc__wrap">
            <div 
                className="vitamin-zinc-header__text"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.zinc.header'})}}
            />
            <div className="vitamin-zinc-header__text2">
                <FormattedMessage id="vitamin.zinc.header2" />
            </div>
            <div className="vitamin-zinc-content">
                <div className="vitamin-zinc-content__item">
                    <img
                        src="/static/svg/vitamin-zinc-point.svg"
                    />
                    <div 
                        className="vitamin-zinc-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.zinc.item.1'})}}
                    />
                </div>
                <div className="vitamin-zinc-content__item">
                    <img
                        src="/static/svg/vitamin-zinc-point.svg"
                    />
                    <div 
                        className="vitamin-zinc-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.zinc.item.2'})}}
                    />
                </div>
                <div className="vitamin-zinc-content__item">
                    <img
                        src="/static/svg/vitamin-zinc-point.svg"
                    />
                    <div 
                        className="vitamin-zinc-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.zinc.item.3'})}}
                    />
                </div>
            </div>
        </div>
        <div className="vitamin-zinc__image">
            <div className="vitamin-zinc__image--wrap">
                <img alt="" src="/static/images/vitamin-zinc-img.png" />
            </div>
            <div className="vitamin-zinc__image--wrap--mobile">
                <img alt="" src="/static/images/vitamin-zinc-img--mobile.png" />
            </div>
        </div>
    </div>
));
