import React, {Component} from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
import Page from "./Page";

class App extends Component {
    render() {
        return (
          <BrowserRouter>
              <Switch>
                  <Route exact path="/ru">
                      <Page locale="ru"/>
                  </Route>
                  <Route path="/">
                      <Page locale="et"/>
                  </Route>
              </Switch>
          </BrowserRouter>
        );
    }
}

export default App;
