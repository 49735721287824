import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-facts" id="vitamin-facts">
        <div 
            className="vitamin-facts-header__text"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.header'})}}
        />
        <div className="vitamin-facts__wrap">
            <div className="vitamin-facts-content">
                <div className="vitamin-facts-content__item">
                    <img
                        src="/static/svg/vitamin-facts-point.svg"
                    />
                    <div 
                        className="vitamin-facts-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.1'})}}
                    />
                </div>
                <div className="vitamin-facts-content__item">
                    <img
                        src="/static/svg/vitamin-facts-point.svg"
                    />
                    <div 
                        className="vitamin-facts-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.2'})}}
                    />
                </div>
                <div className="vitamin-facts-content__item">
                    <img
                        src="/static/svg/vitamin-facts-point.svg"
                    />
                    <div 
                        className="vitamin-facts-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.3'})}}
                    />
                </div>
            </div>
        </div>
        <div className="vitamin-facts__image">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/vitamin-facts-bg--sm-o.png"
                />
                <img 
                    alt=""
                    src="/static/images/vitamin-facts-bg--sm.png"
                />
            </picture>
        </div>
    </div>
));
